function initializeShowMore() {
  $(".review-content-show").each(function () {
    const content = $(this);
    const contentHeight = content.prop('scrollHeight');
    const maxHeight = parseFloat(content.css('max-height'));

    if (contentHeight > maxHeight) {
      content.next(".show-more").removeClass('d-none');
    }
  });

  // $(".evaluation-content-show").each(function () {
  //   const content = $(this);
  //   const contentHeight = content.prop('scrollHeight');
  //   const maxHeight = parseFloat(content.css('max-height'));
  //
  //   if (contentHeight > maxHeight) {
  //     content.next(".show-more").removeClass('d-none');
  //   }
  // });
}

$(document).ready(function () {
  initializeShowMore();

  $(document).on("click", ".show-more", function () {
    const targetId = $(this).data("target");
    const content = $("#" + targetId);

    if (content.hasClass("expanded")) {
      content.removeClass("expanded");
      $(this).text("続きを読む");
    } else {
      content.addClass("expanded");
      $(this).text("表示を減らす");
    }
  });

  // For example:
  $(document).on('ajaxComplete', function() {
    initializeShowMore();
  });
});
document.addEventListener("turbo:load", function() {
  initializeShowMore();
});
