$(document).ready(function() {
  $('.banner-pc a, .banner-sp a').on('click', function(event) {
    var url = $(this).attr('href');
    var advertisementId = $(this).data('id');

    $.ajax({
      url: 'clicks',
      method: 'POST',
      data: {
        advertisement_id: advertisementId,
        _token: $('meta[name="csrf-token"]').attr('content')
      },
      success: function() {
        console.log('Click has been recorded.');
      },
      error: function() {
        console.log('Error while recording the click.');
      },
      complete: function() {
        window.open(url, '_blank');
      }
    });
  });
});
