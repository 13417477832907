// $(document).ready(function() {
//     var page = 1;
//     var isLoading = false;
//     console.log("abc");
//     $('.list-message').click(function(e) {
//       if (!isLoading) {
//         var id = $(this).attr('id');
//         var value = $(this).attr('value');
//         console.log(id);
//       }
//     });
//     // function loadMoreMessages() {
//     //   if (isLoading) return;
//     //   isLoading = true;
//     //   $.ajax({
//     //     url: '/messages/58',
//     //     type: 'GET',
//     //     dataType: 'json',
//     //     data: { page_number: page },
//     //     success: function(response) {
//     //       if (response.length > 0) {
//     //         response.forEach(function(message) {
//     //           $('#message-container').append('<div class="message">' + message.content + '</div>');
//     //         });
//     //         page++;
//     //       } else {
//     //         $(window).off('.infiniteScroll');
//     //       }
//     //       isLoading = false;
//     //     },
//     //     error: function(xhr, status, error) {
//     //       console.error('Error loading messages:', error);
//     //       isLoading = false;
//     //     }
//     //   });
//     // }

//     $(window).on('scroll.infiniteScroll', function() {
//       if ($(window).scrollTop() + $(window).height() >= $(document).height() - 100) {
//         loadMoreMessages();
//       }
//     });

//     loadMoreMessages();
//   });
$(document).on('turbolinks:load', function () {
  $(document).ready(function () {
    $("a.list-message[data-ignore-js]").click(function (event) {
      event.stopPropagation();
    });
    $('.friendship-message').click(function () {
      var messagePath = $(this).find('.message-path a').attr('href');
      window.location.href = messagePath;
    });
    $("#delete-diaries").addClass("modal-disable");
    $('.btn-send-message').click(function (event) {
      var fileInput = $('#mess_create_file');
      var fileInputMb = $('#mess_create_file_mb');
      var textInput = $('.mess-input-text');


      if ((fileInput[0].files.length === 0 && fileInputMb[0].files.length === 0 && textInput.val().trim() === '')) {
        event.preventDefault();
        $('.input-error').removeClass('d-none');
      } else {
        $('.input-error').addClass('d-none');
      }
    });
    $('#message_images').change(function (e) {
      const file = this.files[0];
      if (file) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes

        if (file.size > maxSize) {
          $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
          this.value = '';
          return
        }
      }
      fileInput = $('#message_images');
      var files = fileInput[0].files;
      $('.file-input-text').text("");
      $('.name-img-1').text(files[0].name);
      $('.name-img-2').text(files[1].name);
      $('.name-img-3').text(files[2].name);
    });

    $('#mess_create_file').change(function (e) {
      const file = this.files[0];
      if (file) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes

        if (file.size > maxSize) {
          $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
          this.value = '';
          return
        }
      }
      fileInput = $('#mess_create_file');
      var files = fileInput[0].files;
      if (files.length > 3) {
        $('.name-mess-img-error').removeClass('d-none');
      } else {
        $('.name-mess-img-error').addClass('d-none');
        $('.image-hidden').text("");
        $('.name-mess-img-1').text(files[0].name);
        $('.name-mess-img-2').text(files[1].name);
        $('.name-mess-img-3').text(files[2].name);
      }
    });

    $('#mess_create_file_mb').change(function (e) {
      const file = this.files[0];
      if (file) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes

        if (file.size > maxSize) {
          $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
          this.value = '';
          return
        }
      }
      fileInput = $('#mess_create_file_mb');
      var files = fileInput[0].files;
      if (files.length > 3) {
        $('.name-mess-img-error').removeClass('d-none');
      } else {
        $('.name-mess-img-error').addClass('d-none');
        $('.image-hidden').text("");
        $('.name-mess-img-1').text(files[0].name);
        $('.name-mess-img-2').text(files[1].name);
        $('.name-mess-img-3').text(files[2].name);
      }
    });

    $('#message_images_s3').change(function (e) {
      const filesAmount = this.files.length;
      const file = this.files[0];
      if (!file) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        $('.name-img-1').empty();
        $('.name-img-2').empty();
        $('.name-img-3').empty();
      }
      fileInput = $('#message_images_s3');
      var files = fileInput[0].files;
      if (files.length > 3) {
        $('.file-input-text').removeClass('d-none')
        $('.name-mess-img-error').removeClass('d-none');
        $('.name-img-1').text("");
        $('.name-img-2').text("");
        $('.name-img-3').text("");
      } else {
        $('.file-input-text').addClass('d-none')
        $('.name-mess-img-error').addClass('d-none');
        $('.image-hidden').text("");
        $('.name-img-1').text(files[0].name);
        $('.name-img-2').text(files[1].name);
        $('.name-img-3').text(files[2].name);
      }
    });

    $('.modal-submit').click(function (e) {
      var user_id = $('#message_receiver_id option:selected');
      var textInput = $('#textMess');
      var fileInput = $('#message_images_s3');
      var message_title = $('#titleMess');
      var hasError = false;
      var receiver_id = $('#message_receiver_id').val();

      if (receiver_id.trim() === '') {
        e.preventDefault();
        $('.select-error-modal').removeClass('d-none');
        hasError = true;
      } else {
        $('.select-error-modal').addClass('d-none');
      }

      if (message_title.val().length > 100) {
        e.preventDefault();
        $('.text-title-max').removeClass('d-none');
        hasError = true;
      } else {
        $('.text-title-max').addClass('d-none');
      }

      if (fileInput[0].files.length > 3) {
        e.preventDefault();
        $('.file-error-modal').removeClass('d-none');
        hasError = true;
      } else {
        $('.file-error-modal').addClass('d-none');
      }

      if (fileInput[0].files.length === 0 && textInput.val().trim() === '') {
        e.preventDefault();
        $('.input-error-modal').removeClass('d-none');
        hasError = true;
      } else {
        $('.input-error-modal').addClass('d-none');
      }
      if (!hasError) {
        $('.container-modal').addClass("d-none");
        $('.modal-confirm').removeClass("d-none");
        $('.group-modal').addClass("d-none");
        $('.username-modal').text(user_id.text());
        $('.message-title').text(message_title.val());
        $('.message-modal-content').text(textInput.val());
      }
    })

    $('.modal-back').click(function (e) {
      $('.container-modal').removeClass("d-none");
      $('.modal-confirm').addClass("d-none");
      $('.group-modal').removeClass("d-none");
    });
    $('#modal-message').trigger('click');

    var titleMess = $('#titleMess');
    var textMess = $('#textMess');
    if (window.innerWidth < 480) {
      titleMess.attr('placeholder', 'コメントを入力');
      textMess.attr('placeholder', 'コメントを入力');
    } else {
      titleMess.attr('placeholder', '');
      textMess.attr('placeholder', '');
    }
  });
})
