$(document).on('click', '.edit-input-community', function () {
    $('.form-preview-community').addClass('d-none')
    $('.form-input-community').removeClass('d-none')
})

$(document).on('change', '#field_field', function () {
    const file = this.files[0];
    if (file) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes

        if (file.size > maxSize) {
            $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
            this.value = '';
            $('.img-preview').empty();
            $('.preview-name-img').text('※最大10MBまでアップできます。');
            return
        }
    }
    imagesPreview(this, $('.form-preview-community').find('div.image'));
    $('.img-com').addClass('d-none');
    console.log(this.files[0].name);
    $('.preview-name-img').text(this.files[0].name);
    imagesPreview(this, $('.img-preview'));
});

var imagesPreview = function (input, placeToInsertImagePreview) {
    if (input.files) {
        placeToInsertImagePreview.empty()
        var filesAmount = input.files.length;
        for (i = 0; i < filesAmount; i++) {
            var reader = new FileReader();

            reader.onload = function (event) {
                localStorage.setItem('uploadedImage', JSON.stringify(event.target.result))
                let htmlImagePreview = `<div class="w-25">
                <img src="${event.target.result}" style="width: 100px;" class="w-100 aspect-1 mobject-fit">
              </div>`
                placeToInsertImagePreview.html(htmlImagePreview)
            }
            reader.readAsDataURL(input.files[i]);
        }
    }
}
