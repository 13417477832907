$(document).on('click', '.comment-edit', function () {
  $(this).closest('form.edit_comment').find('.textarea-comment-edit').removeClass('d-none')
  $(this).closest('form.edit_comment').find('.btn-submit-comment-edit').removeClass('d-none')
  $('.comment-edit-title').removeClass('d-none')
  $(this).closest('form.edit_comment').find('.comment-content').addClass('d-none')
  $(this).closest('form.edit_comment').find('.comment-like').addClass('d-none')
})
$(document).on('click', ".checkbox-all", function () {
  $(".checkbox-record").prop("checked", this.checked);
  $("#delete-diaries").removeClass("modal-disable");
});
$(document).on('click', ".checkbox-record", function () {
  let all = $(".checkbox-record").length;
  let count = $(".checkbox-record:checked").length;
  if (all === count) {
    $(".checkbox-all").prop("checked", true);
  } else {
    $(".checkbox-all").prop("checked", false);
  }
  if ($(this).prop("checked")) {
    $("#delete-diaries").removeClass("modal-disable");
  } else {
    if (count === 0) {
      $("#delete-diaries").addClass("modal-disable");
    }
  }
});
$(document).on('click', '.btn-submit-delete', function () {
  let data = $('.checkbox-record').serialize();
  let url = $(this).data('url-submit')
  if (data) {
    $.ajax({
      url: url,
      type: 'DELETE',
      data: data,
      success: function (response) {
        $('#modalDeleteDiary').find('.modal-content').removeClass('p-4');
        $('#modalDeleteDiary').find('.modal-body').html('<h6 class="fw-bold pb-5">削除が完了しました</h6>')
        $('#modalDeleteDiary').find('.modal-footer ').hide()
        $.each(response['diary_ids'], function (key, value) {
          $(`div[data-diary-id=${value}]`).remove()
        })
        window.location.reload()
      },
      error: function (xhr) {
      },
    });
  }
})
$(document).on('input', '#diary_title', function () {
  $(this).closest('.diary-title').find('.count-text').text(this.value.length)
  $('#preview-tab-pane').find('.diary-title').text("プレビュー：" + $(this).val().toString())
  $('.confirm-diary').find('.diary-title').text($(this).val())
})
$(document).on('input', '#answer_body', function () {
  $('.count-text-answer').text(this.value.length)
})
$(document).on('input', '#comment_content', function () {
  $('.count-text-answer').text(this.value.length)
})
$(document).on('input', '#diary_content', function () {
  $(this).closest('.diary-content').find('.count-text').text(this.value.length)
  $('#preview-tab-pane').find('.diary-content').text($(this).val())
  $('.confirm-diary').find('.diary-content').text($(this).val())
})
$(document).on('change', '#diary-file', function () {
  const file = this.files[0];
  if (file) {
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes

    if (file.size > maxSize) {
      $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
      this.value = '';
      $('.diary-image').empty();

      return
    }
  }
  imagesPreview(this, $('#preview-tab-pane').find('.diary-image'));
  imagesPreview(this, $('.confirm-diary').find('.diary-image'));
});
$(document).on('change', '#date_year', function () {
  $('#preview-tab-pane').find('.diary-time-year').text($(this).val())
  $('.confirm-diary').find('.diary-time-year').text($(this).val())
});
$(document).on('change', '#date_month', function () {
  $('#preview-tab-pane').find('.diary-time-month').text($(this).val())
  $('.confirm-diary').find('.diary-time-month').text($(this).val())
});
$(document).on('change', '#date_day', function () {
  $('#preview-tab-pane').find('.diary-time-day').text($(this).val())
  $('.confirm-diary').find('.diary-time-day').text($(this).val())

});
$(document).on('change', '#diary_time', function () {
  $('#preview-tab-pane').find('.diary-time-hour').text($(this).val())
  $('.confirm-diary').find('.diary-time-hour').text($(this).val())
});
$(document).on('click', '.check-diary', function () {
  var title = $('#diary_title').val();
  var area = $('#diary_content').val();
  console.log(title.length);
  var isValid = true;
  if (title === '') {
    $('.text-error-title').removeClass('d-none');
    isValid = false;
  } else {
    $('.text-error-title').addClass('d-none');
  }

  if (title.length > 100) {
    $('.text-error-title-max').removeClass('d-none');
    isValid = false;
  } else {
    $('.text-error-title-max').addClass('d-none');
  }

  if (area === '') {
    $('.text-error-area').removeClass('d-none');
    isValid = false;
  } else {
    $('.text-error-area').addClass('d-none');
  }
  if (!isValid) {
    e.preventDefault();
  }
  $('.input-diary').addClass('d-none')
  $('.confirm-diary').removeClass('d-none')
});
$(document).on('click', '.back-to-input-diary', function () {
  $('.input-diary').removeClass('d-none')
  $('.confirm-diary').addClass('d-none')
});
document.addEventListener("DOMContentLoaded", function () {
  const link = document.getElementById("create-tab");
  const div = document.getElementById("back-to-create");
  if (div && link) {
    div.addEventListener("click", function () {
      link.click();
    });
  }
});
$(document).on('click', '#diary-month', function () {
  var selectedMonth = this.value;
  var daySelect = document.getElementById("diary-day");
  var yearSelect = document.getElementById("diary-year").value;

  daySelect.innerHTML = "";

  var daysInMonth = new Date(yearSelect, selectedMonth, 0).getDate();
  for (var i = 1; i <= daysInMonth; i++) {
    var option = document.createElement("option");
    option.text = i;
    option.value = i;
    daySelect.appendChild(option);
  }
});

$(document).on('click', '#preview-tab', function () {
  const monthSelect = $("#diary-month").val();
  const yearSelect = $("#diary-year").val();
  const daySelect = $("#diary-day").val();
  const hourSelect = $("#diary-hour").val();

  $(".diary-time-year").text(yearSelect);
  $(".diary-time-month").text(monthSelect);
  $(".diary-time-day").text(daySelect);
  $(".diary-time-hour").text(hourSelect.toString() + ":00");
});

$(document).on("click", "#file-click", function () {
  const diary = $("#diary-file");
  if (diary) {
    diary.click();
  }
});

$(document).on('change', '#diary-file', function () {
  const diary = document.getElementById("diary-file");
  const fileName = document.getElementById("file-name");
  const maxChars = 20;
  const filesAmount = this.files.length;
  for (let i = 0; i < filesAmount; i++) {
    const file = this.files[i];
    if (file) {
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      if (file.size > maxSize) {
        $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
        this.value = '';
        $('.diary-image').empty();
        return
      }
    }
  }
  const selectedFile = diary.files[0];
  let text = "";
  if (selectedFile) {
    console.log(diary.files.length);
    if (diary.files.length > 3) {
      $('.file-error').removeClass('d-none');
      $('#diary-file').val('');
      $('.cm_input_file_text').text('※最大10MBまでアップできます。');
    } else if (diary.files.length > 1) {
      console.log(diary.files.length);
      fileName.textContent = `${diary.files.length}つのファイル`;
      $('.file-error').addClass('d-none');
    } else {
      $('.file-error').addClass('d-none');
      text = selectedFile.name;
      text =
        text.length > maxChars
          ? text.substring(0, maxChars / 2) +
          "..." +
          text.substring(text.length - maxChars / 2)
          : text;
      fileName.textContent = text;
    }
  } else {
    fileName.textContent = "※最大10MBまでアップできます。";
  }
});

var imagesPreview = function (input, placeToInsertImagePreview) {
  if (input.files.length < 4) {
    placeToInsertImagePreview.empty()
    var filesAmount = input.files.length;
    for (i = 0; i < filesAmount; i++) {
      var reader = new FileReader();

      reader.onload = function (event) {
        let htmlImagePreview = `<div class="w-25">
                    <img src="${event.target.result}" style="width: 100px;" class="w-100 aspect-1 mobject-fit">
                  </div>`
        $(htmlImagePreview).appendTo(placeToInsertImagePreview);
      }
      reader.readAsDataURL(input.files[i]);
    }
  }
}
$(document).on('click', '.diaries-content', function () {
  $('.diaries-content').addClass('d-none');
  $('.diaries-content-short').removeClass('d-none');
  $('.diary-content-sort').addClass('d-none');
  $('.diary-content').removeClass('d-none');
});

$(document).on('click', '.posts-content', function () {
  $('.posts-body').removeClass('limit-line-3');
  $('.posts-content').addClass('d-none');
  $('.posts-content-1').removeClass('d-none');
});

$(document).on('click', '.posts-content-1', function () {
  $('.posts-body').addClass('limit-line-3');
  $('.posts-content-1').addClass('d-none');
  $('.posts-content').removeClass('d-none');
});

$(document).on('click', '.diaries-content-short', function () {
  $('.diaries-content').removeClass('d-none');
  $('.diaries-content-short').addClass('d-none');
  $('.diary-content-sort').removeClass('d-none');
  $('.diary-content').addClass('d-none');
});

$(document).on('click', '.comments-load-more', function () {
  $('.comments-short').addClass('d-none');
  $('.comments-long').removeClass('d-none');
  $('.comments-load-more').addClass('d-none');
  $('.comments-load-short').removeClass('d-none');
});

$(document).on('click', '.comments-load-short', function () {
  $('.comments-long').addClass('d-none');
  $('.comments-short').removeClass('d-none');
  $('.comments-load-more').removeClass('d-none');
  $('.comments-load-short').addClass('d-none');
});

$(document).ready(function () {
  if ($('.input_diary').length > 0) {
    let diaryTitle = $('#diary_title').val();
    let diaryContent = $('#diary_content').val();
    let monthSelect = $("#diary-month").val();
    let yearSelect = $("#diary-year").val();
    let daySelect = $("#diary-day").val();
    let hourSelect = $("#diary-hour").val();
    $('.diary-title-input').find('.count-text').text(diaryTitle.length)
    $('#preview-tab-pane').find('.diary-title').text("プレビュー：" + diaryTitle.toString())
    $('.confirm-diary').find('.diary-title').text(diaryTitle)
    $('.diary-content-input').find('.count-text').text(diaryContent.length)
    $('#preview-tab-pane').find('.diary-content').text(diaryContent)
    $('.confirm-diary').find('.diary-content').text(diaryContent)
    $('#preview-tab-pane').find('.diary-time-year').text(yearSelect)
    $('.confirm-diary').find('.diary-time-year').text(yearSelect)
    $('#preview-tab-pane').find('.diary-time-month').text(monthSelect)
    $('.confirm-diary').find('.diary-time-month').text(monthSelect)
    $('#preview-tab-pane').find('.diary-time-day').text(daySelect)
    $('.confirm-diary').find('.diary-time-day').text(daySelect)
    $('#preview-tab-pane').find('.diary-time-hour').text(hourSelect)
    $('.confirm-diary').find('.diary-time-hour').text(hourSelect)
  }
})