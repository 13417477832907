$(document).on('turbolinks:load', function () {
  $(() => {
    $('#question_category_id_1').trigger("click");
    $('#post_image_s3').change(function (e) {
      const file = this.files[0];
      if (!file) {
        $(".post-image-1-show").prop("src", "");
        $(".post-image-1-show").prop("style", "");
        $('.post-image-1').text("※最大10MBまでアップできます。");
        return
      }
      fileInput = $('#post_image_s3');
      var files = fileInput[0].files;
      previewImage('#post_image_s3', '.post-image-1-show');
      $('.post-image-1').text("");
      $('.post-image-1').text(files[0].name);
    })
    $('#post_image_second_s3').change(function (e) {
      const file = this.files[0];
      if (!file) {
        $(".post-image-2-show").prop("src", "");
        $(".post-image-2-show").prop("style", "");
        $('.post-image-2').text("※最大10MBまでアップできます。");
        return
      }
      fileInput = $('#post_image_second_s3');
      var files = fileInput[0].files;
      previewImage('#post_image_second_s3', '.post-image-2-show');
      $('.post-image-2').text("");
      $('.post-image-2').text(files[0].name);
    });

    $('#post_image_third_s3').change(function (e) {
      const file = this.files[0];
      if (!file) {
        $(".post-image-3-show").prop("src", "");
        $(".post-image-3-show").prop("style", "");
        $('.post-image-3').text("※最大10MBまでアップできます。");
        return
      }
      fileInput = $('#post_image_third_s3');
      previewImage('#post_image_third_s3', '.post-image-3-show');
      var files = fileInput[0].files;
      $('.post-image-3').text(files[0].name);
    });

    $('.post-body-more').click(function () {
      $('.text-body-truncate').addClass('d-none');
      $('.text-body-full').removeClass('d-none');
      $('.post-body-more').addClass('d-none');
      $('.post-body-low').removeClass('d-none');
    })
    $('.post-body-low').click(function () {
      $('.text-body-truncate').removeClass('d-none');
      $('.text-body-full').addClass('d-none');
      $('.post-body-more').removeClass('d-none');
      $('.post-body-low').addClass('d-none');
    })

    $('.text-submit-post').click(function (e) {
      var title = $('#post_title').val();
      var body = $('#post_body').val();
      var hasError = false;

      if (title.trim() === '') {
        $('.error-title-post').removeClass('d-none');
        hasError = true;
      } else {
        $('.error-title-post').addClass('d-none');
      }

      if (title.length > 100) {
        $('.error-title-post-text').removeClass('d-none');
        hasError = true;
      } else {
        $('.error-title-post-text').addClass('d-none');
      }

      if (body === '') {
        $('.error-body-post').removeClass('d-none');
        hasError = true;
      } else {
        $('.error-body-post').addClass('d-none');
      }

      if (!hasError) {

        $('.post-form').addClass('d-none');
        $('.form-post-confirm').removeClass('d-none');

        $('.title-post').text(title);
        $('.body-post').text(body);
      }
      handleFileSelect();
      e.preventDefault();
    });


    $('.btn-post-fix').click(function (e) {
      $('.post-form').removeClass('d-none');
      $('.form-post-confirm').addClass('d-none')
      $('.post-error').addClass('d-none');
    })

    function previewImage(inputSelector, imgSelector) {
      console.log("test");
      var fileInput = $(inputSelector)[0];
      var files = fileInput.files;
      if (files && files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          console.log(e.target.result)
          $(imgSelector).attr('src', e.target.result);
          $(imgSelector).css({
            'width': '140px',
            'height': '140px',
            'padding-top': '10px'
          });
          $(imgSelector).show();
        };

        reader.readAsDataURL(files[0]);
      }
    }

    function handleFileSelect() {
      let element = $($('.image-preview')[0]);
      let element1 = $($('.image-preview')[1]);
      let images = [$('#post_image_s3')[0].files[0], $('#post_image_second_s3')[0].files[0], $('#post_image_third_s3')[0].files[0]];
      for (let i = 0; i < 3; i++) {
        let reader = new FileReader();
        if (images[i]) {
          reader.addEventListener("load", function () {
            var image = new Image();
            image.height = 140;
            image.width = 140;
            image.title = images[i].name;
            image.src = this.result;
            image.style = "padding-right: 10px; padding-bottom: 10px";
            if (element.find('img').length >= i && element.children('img').eq(i).length) {
              element.find('img').eq(i).replaceWith(image);
            }
            else {
              element.append(image);
            }
          }, false);
          reader.readAsDataURL(images[i]);
        }
      }
    }

  });
  $(document).on('click', '.diary-read-more', function (e) {
    e.preventDefault()
    $(this).closest('div.view-description-diary').siblings(".short-diary").addClass('d-none')
    console.log($(this).closest('div.view-description-diary'));
    $(this).closest('div.view-description-diary').find('div.full-diary').removeClass('d-none')
    $(this).addClass('d-none d-md-none')
    $(this).closest('div').find('a.diary-display-less').removeClass('d-none d-md-none')
  })
  $(document).on('click', '.diary-display-less', function (e) {
    e.preventDefault()
    // $(this).closest('div.view-description').find('div.short-description').removeClass('d-none')
    $(this).closest('div.view-description-diary').siblings(".short-diary").removeClass('d-none')
    $(this).closest('div.view-description-diary').find('div.full-diary').addClass('d-none')
    $(this).addClass('d-none d-md-none')
    $(this).closest('div').find('a.diary-read-more').removeClass('d-none d-md-none')
  })
})
