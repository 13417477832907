$(document).on('click', 'input[type=radio]', function () {
  if (this.id === 'unknown-radio') {
    if (this.previous) {
      this.checked = false;
    }
    this.previous = this.checked;
    handleRadioClick();
  }
})

function handleRadioClick() {
  var isChecked = $('#unknown-radio').is(':checked');
  var yearSelect = $('#year-select');
  var monthSelect = $('#month-select');
  var daySelect = $('#day-select');

  if (isChecked) {
    yearSelect.prop('disabled', true).val('');
    monthSelect.prop('disabled', true).val('');
    daySelect.prop('disabled', true).val('');

  } else {
    yearSelect.prop('disabled', false);
    monthSelect.prop('disabled', false);
    daySelect.prop('disabled', false);
  }
}

$(document).on('click', '.modal-question .btn-confirm', function () {
  let modalCreate = $(this).closest('.modal-question')
  var content = modalCreate.find('div.input-answer').find('textarea.input-answer').val();
  if (content === "") {
    $('.answer-content-error').removeClass('d-none')
  } else {
    $('.answer-content-error').addClass('d-none')
    modalCreate.find('.preview-image').empty()
    modalCreate.find('.preview-answer .preview-text').text(modalCreate.find('div.input-answer').find('textarea.input-answer').val())
    modalCreate.find('div.input-answer').addClass('d-none')
    modalCreate.find('.btn-confirm').addClass('d-none')
    modalCreate.find('.close-modal').addClass('d-none')
    modalCreate.find('div.preview-answer').removeClass('d-none')
    modalCreate.find('.btn-edit').removeClass('d-none')
    modalCreate.find('.btn-submit').removeClass('d-none')
  }
})
$(document).ready(function () {
  // Periodically check if the modal is closed
  setInterval(function () {
    if (!$('.modal-question').hasClass('show')) {
      resetModal(); // Call the reset function if the modal is hidden
    }
  }, 500); // Adjust interval time as needed

  // Backup for when clicking outside or pressing Esc key
  $(document).on('click', '.modal-backdrop', function () {
    resetModal();
  });
});

// Function to reset modal content
function resetModal() {
  $('.answer-content-error').addClass('d-none');
}


$(document).on('click', '.modal-question .btn-edit', function () {
  let modalCreate = $(this).closest('.modal-question')
  modalCreate.find('div.input-answer').removeClass('d-none')
  modalCreate.find('.btn-confirm').removeClass('d-none')
  modalCreate.find('.close-modal').removeClass('d-none')
  modalCreate.find('div.preview-answer').addClass('d-none')
  modalCreate.find('.btn-edit').addClass('d-none')
  modalCreate.find('.btn-submit').addClass('d-none')
})
// $(document).on('change', '.modal-question #answer_images', function () {
//     if (this.files.length > 3) {
//         alert('3枚まで')
//     }
//     $(this).val('')
// })

$(document).on('click', '.edit-input-question', function () {
  $('.form-preview-question').addClass('d-none')
  $('.form-input-question').removeClass('d-none')
})

$(document).ready(function () {
  $('#question_body').on('input', function (event) {
    $('.text-count').html($('#question_body').val().length)
  })
  $("form[id^='answer_modal']").each(function (i, element) {
    $(element).find('.count-text-answer').text($(element).find('#answer_body').val().length)
    $(element).find('#answer_body').on('keyup', function (event) {
      let answerBody = $(event.target)
      if (answerBody.val().length > 4000) {
        answerBody.parent().addClass('error-answer-text-area')
        $(answerBody.parent().siblings('.answer-image-wrapper')[0]).find('.answer-image').addClass('error-answer-image')
        $(answerBody.parents('form')[0]).find('.btn-confirm').prop('disabled', true)
      } else {
        answerBody.parent().removeClass('error-answer-text-area')
        $(answerBody.parent().siblings('.answer-image-wrapper')[0]).find('.answer-image').removeClass('error-answer-image')
        $(answerBody.parents('form')[0]).find('.btn-confirm').prop('disabled', false)
      }
    })
  })

  $("#createAnswer form").each(function (i, element) {
    $(element).find('#answer_body').on('keyup', function (event) {
      let answerBody = $(event.target)
      if (answerBody.val().length > 4000) {
        answerBody.parent().addClass('error-answer-text-area')
        $(answerBody.parent().siblings('.answer-image-wrapper')[0]).find('.answer-image').addClass('error-answer-image')
        $(answerBody.parents('form')[0]).find('.btn-confirm').prop('disabled', true)
      } else {
        answerBody.parent().removeClass('error-answer-text-area')
        $(answerBody.parent().siblings('.answer-image-wrapper')[0]).find('.answer-image').removeClass('error-answer-image')
        $(answerBody.parents('form')[0]).find('.btn-confirm').prop('disabled', false)
      }
    })
  })
});

$(document).ready(function () {
  function handleImagePreview(input) {
    let file = input.files[0];
    let previewSelector = $(input).data("preview");
    let $previewContainer = $("." + previewSelector);
    let fileNameSelector = $(input).data("name");
    let $fileNameContainer = $("." + fileNameSelector);

    $previewContainer.empty();

    if (file) {
      $fileNameContainer.text(file.name);

      let reader = new FileReader();
      reader.onload = function (e) {
        let img = $("<img>", {
          src: e.target.result,
          class: "rounded mobject-fit",
          css: {
            width: "100px",
            height: "100px"
          }
        });
        $previewContainer.append(img);
      };
      reader.readAsDataURL(file);
    } else {
      $fileNameContainer.text("※最大10MBまでアップできます。");
    }
  }

  $(".input-file").on("change", function () {
    const file = this.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      if (file.size > maxSize) {
        $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
        $('#image_modal').show();
        $('#image_modal').addClass('show')
        $(document).on('click', '#image_modal button', function () {
          $('#image_modal').remove();
        })
        this.value = '';
        let previewSelector = $(this).data("preview");
        let $previewContainer = $("." + previewSelector);
        $previewContainer.empty();
        let fileNameSelector = $(this).data("name");
        let $fileNameContainer = $("." + fileNameSelector);
        $fileNameContainer.text("※最大10MBまでアップできます。");
        return
      }
    }
    handleImagePreview(this);
  });

  $('#createAnswerModal').on('shown.bs.modal', function () {
    $(".input-file").off("change").on("change", function () {
      const file = this.files[0];
      if (file) {
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes

        if (file.size > maxSize) {
          $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
          $('#image_modal').show();
          $('#image_modal').addClass('show')
          $(document).on('click', '#image_modal button', function () {
            $('#image_modal').remove();
          })
          this.value = '';
          return
        }
      }
      handleImagePreview(this);
    });
  });
});

$('.modal').on('hidden.bs.modal', function () {
  console.log("test erro")
  $('.answer-content-error').addClass('d-none');
});

$(document).ready(function () {
  $(document).on("click", ".preview-question", function () {
    const radioButtonTextMapping = {
      1: "犬",
      2: "猫",
      3: "その他ペット",
    };

    const subcategories = {
      1: "小型犬",
      2: "中型犬",
      3: "大型犬",
      4: "短毛種",
      5: "長毛種",
      6: "短毛種・長毛種",
      7: "小動物",
      8: "観賞魚",
      9: "鳥",
      10: "爬虫類&両生類",
      11: "昆虫",
      12: "その他",
    };

    let isValid = true;

    $("input, select, textarea").each(function () {
      let fieldName = $(this).attr("name");
      let value = $(this).val();
      let errorMessage = "";

      if (fieldName === "question[category_id]" && !value) {
        errorMessage = "※大分類とを入力してください";
      } else if (fieldName === "question[subcategory_id]" && !value) {
        errorMessage = "※中分類とを入力してください";
      } else if (fieldName === "question[special_category]" && !value) {
        errorMessage = "※小分類とを入力してください";
      } else if (fieldName === "question[body]" && value.length < 50) {
        errorMessage = "※50文字以上で入力してください。";
      } else if (fieldName === "question[tag]" && !value) {
        errorMessage = "※キーワードとを入力してください";
      } else if (fieldName === "question[title]" && !value) {
        errorMessage = "※題名とを入力してください";
      }

      if (errorMessage) {
        isValid = false;
        $(this).closest("div.validate-field").append(
          `<div class="text-main fw-bold text-small">${errorMessage}</div>`
        );
      }
    });

    if (isValid) {
      let formPreviewQuestion = $(".form-preview-question");
      formPreviewQuestion.removeClass("d-none");
      $(".form-input-question").addClass("d-none");

      let questionBody = $("#question_body").val();
      let inputValue = $('input[name="question[category_id]"]:checked').val();
      let subcategoryValue = $("#subcategory-select4").val();

      formPreviewQuestion.find(".question-body").text(questionBody || "（未入力）");
      formPreviewQuestion.find(".name-special-category").html(
        radioButtonTextMapping[inputValue] || "（未選択）"
      );
      formPreviewQuestion.find(".name-subcategory_id").html(
        subcategories[subcategoryValue] || "（未選択）"
      );
      formPreviewQuestion.find(".question-tag").text($("#question_tag").val() || "（未入力）");

      let questionTitle = $("#question_title").val();
      formPreviewQuestion.find(".question-title").text(questionTitle || "（未入力）");
    } else {
      $("html, body").animate({scrollTop: 0}, 0);
      $("div.error-notice").html(
        `<div class="bg-main text-white fw-bold py-2 w-100 mb-5 px-4">
          ※エラー項目があります（お困りごと）
        </div>`
      );
    }
  });
});


