$(document).ready(function () {

  $("form[id='new_comment']").each(function (i, element) {
    $(element).find('#comment_content').on('keyup', function (event) {
      let commentContent = $(event.target)
      if (commentContent.val().length > 2000) {
        commentContent.addClass('error-answer-text-area')
        $(commentContent.parent().siblings('.answer-image-wrapper')[0]).find('.answer-image').addClass('error-answer-image')
        $(commentContent.parents('form')[0]).find('.btn-confirm').prop('disabled', true)
      } else {
        commentContent.removeClass('error-answer-text-area')
        $(commentContent.parent().siblings('.answer-image-wrapper')[0]).find('.answer-image').removeClass('error-answer-image')
        $(commentContent.parents('form')[0]).find('.btn-confirm').prop('disabled', false)
      }
    })
  })

  $('.rating').each(function () {
    var rating = $(this).data('rating');
    $(this).starRating({
      showInfo: false,
      starIconEmpty: 'far fa-star',
      starIconFull: 'fas fa-star',
      starColorEmpty: '#FFC107',
      starsSize: 1, // em
      stars: rating,
      callback: function (currentRating, $el) {
        console.log('Rated ' + currentRating + ' stars');
      }
    });
  });

  $(document).on('input', '#content', function () {
    var error = $(this).closest('.input-answer-body').find('.count-answer-error');
    $(this).siblings('.count-answer').find('.count-text-answer').text(this.value.length);

    if (this.value.length > 2000) {
      error.removeClass("d-none");
    } else {
      error.addClass("d-none");
    }
  });


  $(document).on('click', '.btn-edit-review', function () {
    let modal = $($(this).data('bs-target'))
    let answerLength = modal.find('.count-text-answer')
    let content = modal.find('#content')
    let rating = modal.find('.evaluation-rating')
    modal.find('.input-answer').removeClass('d-none')
    modal.find('.btn-confirm').removeClass('d-none')
    modal.find('.close-modal').removeClass('d-none')
    modal.find('.preview-answer').addClass('d-none')
    modal.find('.btn-edit').addClass('d-none')
    modal.find('.btn-submit').addClass('d-none')
    $('i').off("click", blockClickEvent)
    modal.find('.content-error').addClass('d-none')
    modal.find('#content').parent().css('border-color', '#333333')
    content.val($(this).parent().siblings('.cm_pre_line').text())
    answerLength.text(content.val().length)
    rating.removeClass('js-wc-star-rating').empty()
    rating.starRating({
      showInfo: false,
      starIconEmpty: 'far fa-star',
      starIconFull: 'fas fa-star',
      starColorEmpty: '#FFC107',
      starsSize: 1.5,
      stars: 5,
      defaultValue: rating.data('rating')
    });
    rating.siblings('#evaluation_rating').val(rating.data('rating'))
  })

  $(document).on('click', '.btn-create-review', function () {
    let modal = $($(this).data('bs-target'))
    let answerLength = modal.find('.count-text-answer')
    let content = modal.find('#content')
    let rating = modal.find('.evaluation-rating')
    modal.find('.input-answer').removeClass('d-none')
    modal.find('.btn-confirm').removeClass('d-none')
    modal.find('.close-modal').removeClass('d-none')
    modal.find('.preview-answer').addClass('d-none')
    modal.find('.btn-edit').addClass('d-none')
    modal.find('.btn-submit').addClass('d-none')
    $('i').off("click", blockClickEvent)
    modal.find('.content-error').addClass('d-none')
    modal.find('#content').parent().css('border-color', '#333333')
    content.val('')
    answerLength.text(0)
    rating.removeClass('js-wc-star-rating').empty()
    rating.starRating({
      showInfo: false,
      starIconEmpty: 'far fa-star',
      starIconFull: 'fas fa-star',
      starColorEmpty: '#FFC107',
      starsSize: 1.5,
      stars: 5,
      defaultValue: 0
    });
    rating.siblings('#evaluation_rating').val(0)
  })

  $(document).on('change', '.evaluation-rating',
    function (e, stars, index) {
      $(this).siblings('#evaluation_rating').val(stars)
    });

  $(document).on('click', '.modal-evaluation .btn-confirm', function () {
    let modal = $(this).closest('.modal-evaluation')
    if (modal.find('#content').val()) {
      modal.find('.preview-answer .preview-text').text(modal.find('div.input-answer').find('textarea.input-answer').val())
      modal.find('.input-answer').addClass('d-none')
      modal.find('.btn-confirm').addClass('d-none')
      modal.find('.close-modal').addClass('d-none')
      modal.find('.preview-answer').removeClass('d-none')
      modal.find('.btn-edit').removeClass('d-none')
      modal.find('.btn-submit').removeClass('d-none')
      $('i').on("click", blockClickEvent)
    } else {
      modal.find('.content-error').removeClass('d-none')
      modal.find('#content').parent().css('border-color', '#FF2424')

    }
  })

  $(document).on('click', '.modal-evaluation .btn-edit', function () {
    let modal = $(this).closest('.modal-evaluation')
    if (modal.find('#content').val()) {
      modal.find('.preview-answer .preview-text').text(modal.find('div.input-answer').find('textarea.input-answer').val())
      modal.find('.input-answer').removeClass('d-none')
      modal.find('.btn-confirm').removeClass('d-none')
      modal.find('.close-modal').removeClass('d-none')
      modal.find('.preview-answer').addClass('d-none')
      modal.find('.btn-edit').addClass('d-none')
      modal.find('.btn-submit').addClass('d-none')
      $('i').off("click", blockClickEvent)
      modal.find('.content-error').addClass('d-none')
      modal.find('#content').parent().css('border-color', '#333333')
    }
  })

  $(document).on('click', '.modal-review .btn-confirm', function () {
    let modal = $(this).closest('.modal-review')
    if (modal.find('#content').val()) {
      modal.find('.preview-answer .preview-text').text(modal.find('div.input-answer').find('textarea.input-answer').val())
      modal.find('.input-answer').addClass('d-none')
      modal.find('.btn-confirm').addClass('d-none')
      modal.find('.close-modal').addClass('d-none')
      modal.find('.preview-answer').removeClass('d-none')
      modal.find('.btn-edit').removeClass('d-none')
      modal.find('.btn-submit').removeClass('d-none')
    } else {
      modal.find('.content-error').removeClass('d-none')
      modal.find('#content').parent().css('border-color', '#FF2424')

    }
  })

  $(document).on('click', '.modal-review .btn-edit', function () {
    let modal = $(this).closest('.modal-review')
    if (modal.find('#content').val()) {
      modal.find('.preview-answer .preview-text').text(modal.find('div.input-answer').find('textarea.input-answer').val())
      modal.find('.input-answer').removeClass('d-none')
      modal.find('.btn-confirm').removeClass('d-none')
      modal.find('.close-modal').removeClass('d-none')
      modal.find('.preview-answer').addClass('d-none')
      modal.find('.btn-edit').addClass('d-none')
      modal.find('.btn-submit').addClass('d-none')
      modal.find('.content-error').addClass('d-none')
      modal.find('#content').parent().css('border-color', '#333333')
    }
  })

  function blockClickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  if ($('#modalNotiSuccess')[0]) {
    $('.close-modal-noti-success').on('click', function () {
      $('#modalNotiSuccess').css('display', 'none')
      $('.modal-backdrop').remove()
    })
  }
});
