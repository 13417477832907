// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// js
import "css/site"
import 'jquery'
import '@popperjs/core'
import 'bootstrap'
import "css/main_register"
import "./main_register"
import './message'
import './navigation'
import './diary'
import './event'
import './question'
import './community'
import "css/notification"
import "css/post"
import './post'
import "css/search"
import "css/navigation"
import "css/common"
import "css/additional_info"
import "css/footer"
import "css/registration"
import "css/rank"
import "css/hotel"
import "./jquery.star-rating.js"
import "css/owner_register"
import "./preview_owner"
import "css/hospital"
import "css/my_page"
import "css/crawl"
import "css/report"
import "css/advertisement"
import "css/zenmarugothic"
import "./advertisement"
import "./inquiry"
import "./success_inquiry"
import "css/my_pet"
import "css/layout_my_page"
import "css/icon_transaction"
import "./icon_random"

$(document).on('turbolinks:load', function () {

    // navigation
    var animatedIcon = document.querySelector(".animated-icon");
    if(animatedIcon) {
        if (animatedIcon.classList.contains("open")) {
            document.querySelector("#app_body").classList.add("d-none");
        } else {
          if(document.querySelector(".navigation_mobile_item")) {
            document.querySelector(".navigation_mobile_item").classList.add("d-none");
          }
        }
    }

    if(document.querySelector(".button-menu")) {
        document.querySelector(".button-menu").addEventListener("click", function () {
            document.querySelector(".animated-icon").classList.toggle("open");
            document.querySelector("#app_body").classList.toggle("d-none");
            document
              .querySelector(".navigation_mobile_item")
              .classList.toggle("d-none");
        });
    }
    // end navigation

    $('.toggle-comment-form').on('click', function () {
        $(this).siblings('.comment-form').toggle();
    });

    $('.toggle-new-comment-form').on('click', function (event) {
        $(this).siblings('.new-comment-form').toggle();
    });

    checkPrefectureSelection();
    // Get the category and subcategory select elements
    var categorySelect = $('#category-select');
    var subcategorySelect = $('#subcategory-select');

    // Handle the change event of the category select
    categorySelect.on('change', function () {
        var categoryId = $(this).val();
        console.log(categoryId);

        // Make an AJAX request to fetch the subcategories for the selected category
        $.ajax({
            url: '/categories/' + categoryId + '/subcategories',
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                // Clear the existing subcategory options
                subcategorySelect.empty();
                // console.log(categoryId);

                // // Add the default prompt option
                // subcategorySelect.append('<option value="">中分類を選んでください</option>');

                // Append the fetched subcategories as options
                $.each(data, function (index, subcategory) {
                    subcategorySelect.append('<option value="' + subcategory.id + '">' + subcategory.name + '</option>');
                });
            },
            error: function () {
                console.log('Error fetching subcategories.');
            }
        });
    });


    // Get the category and subcategory select elements
    var categorySelect2 = $('#category-select2');
    var subcategorySelect2 = $('#subcategory-select2');

    // Handle the change event of the category select
    // var radioContainer = $('.btn-check');
    $(document).on('change', '.btn-category', function () {
        var categoryId = $(this).val()
        console.log(categoryId)

        // Make an AJAX request to fetch the subcategories for the selected category
        $.ajax({
            url: '/categories/' + categoryId + '/subcategories',
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                // Clear the existing subcategory options
                subcategorySelect2.empty();

                // Add the default prompt option
                //   subcategorySelect2.append('<option value="">Select Subcategory</option>');

                // Append the fetched subcategories as options
                $.each(data, function (index, subcategory) {
                    subcategorySelect2.append('<option value="' + subcategory.id + '">' + subcategory.name + '</option>');
                });
            },
            error: function () {
                console.log('Error fetching subcategories.');
            }
        });
    });

    // Get the category and subcategory select elements
    var categorySelect2 = $('#category-select4');
    var subcategorySelect2 = $('#subcategory-select4');

    // Handle the change event of the category select
    // var radioContainer = $('.btn-check');
    $(document).on('change', '.btn-category', function () {
        var categoryId = $(this).val()

        // Make an AJAX request to fetch the subcategories for the selected category
        $.ajax({
            url: '/categories/' + categoryId + '/subcategories',
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                // Clear the existing subcategory options
                subcategorySelect2.empty();

                // Add the default prompt option
                //   subcategorySelect2.append('<option value="">Select Subcategory</option>');

                // Append the fetched subcategories as options
                $.each(data, function (index, subcategory) {
                    subcategorySelect2.append('<option value="' + subcategory.id + '">' + subcategory.name + '</option>');
                });
            },
            error: function () {
                console.log('Error fetching subcategories.');
            }
        });
    });


    // Get the category and subcategory select elements
    var categorySelect3 = $('#category-select3');
    var subcategorySelect3 = $('#subcategory-select3');

    // Handle the change event of the category select
    categorySelect3.on('change', function () {
        var categoryId = $(this).val();

        // Make an AJAX request to fetch the subcategories for the selected category
        $.ajax({
            url: '/categories/' + categoryId + '/subcategories',
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                // Clear the existing subcategory options
                subcategorySelect3.empty();

                // Add the default prompt option
                //   subcategorySelect2.append('<option value="">Select Subcategory</option>');

                // Append the fetched subcategories as options
                $.each(data, function (index, subcategory) {
                    subcategorySelect3.append('<option value="' + subcategory.id + '">' + subcategory.name + '</option>');
                });
            },
            error: function () {
                console.log('Error fetching subcategories.');
            }
        });
    });

    $('#filterButton').click(function () {
        $('#filterBox').toggle();
    });


    $('#pet-prefecture-select').on('change', function () {
        var selectedRegion = $(this).val();
        var citySelect = $('#pet-city-select');
        console.log(citySelect);

        // Fetch the JSON data using AJAX
        $.ajax({
            url: '/prefectures_with_cities.json',
            method: 'GET',
            dataType: 'json',
            success: function (regionsWithCities) {
                var cities = regionsWithCities[selectedRegion] || [];

                // Clear existing city options and add the new ones based on the cities array
                citySelect.empty();
                citySelect.append($('<option>', {value: '', text: '市町村を選択'}));
                $.each(cities, function (index, city) {
                    citySelect.append($('<option>', {value: city, text: city}));
                });
            },
            error: function () {
                console.log('Error fetching regions with cities.');
            }
        });
    })

    $('.prefecture-select-box').on('change', function () {
        var selectedRegion = $(this).val();
        var citySelect = $('.city-select-box');

        // Fetch the JSON data using AJAX
        $.ajax({
            url: '/prefectures_with_cities.json',
            method: 'GET',
            dataType: 'json',
            success: function (regionsWithCities) {
                var cities = regionsWithCities[selectedRegion] || [];

                // Clear existing city options and add the new ones based on the cities array
                citySelect.empty();
                citySelect.append($('<option>', {value: '', text: '市町村を選択'}));
                $.each(cities, function (index, city) {
                    citySelect.append($('<option>', {value: city, text: city}));
                });
            },
            error: function () {
                console.log('Error fetching regions with cities.');
            }
        });
    })


});

function checkPrefectureSelection() {
    var selectedPrefecture = $('.prefecture-select').val();
    if (selectedPrefecture) {
        fetchCitiesForPrefecture(selectedPrefecture);
    }
}

function fetchCitiesForPrefecture(prefecture) {
    var citySelect = $('.city-select');
    var cityVal = $('#hid_city').val();

    // Fetch the JSON data using AJAX
    $.ajax({
        url: '/prefectures_with_cities.json',
        method: 'GET',
        dataType: 'json',
        success: function (regionsWithCities) {
            var cities = regionsWithCities[prefecture] || [];

            // Clear existing city options and add the new ones based on the cities array
            citySelect.empty();
            citySelect.append($('<option>', {value: '', text: '市町村を選択'}));
            $.each(cities, function (index, city) {
                if (cityVal != undefined && cityVal == city) {
                    citySelect.append($('<option>', {value: city, text: city, selected: "selected"}));
                } else {
                    citySelect.append($('<option>', {value: city, text: city}));
                }
            });
        },
        error: function () {
            console.log('Error fetching regions with cities.');
        }
    });
}

$('.prefecture-select').on('change', function () {
    var selectedRegion = $(this).val();
    fetchCitiesForPrefecture(selectedRegion);
});

document.addEventListener("DOMContentLoaded", function () {
    // 編集ボタンがクリックされた時の処理
    document.querySelectorAll('.edit-answer-btn').forEach(function (btn) {
        btn.addEventListener('click', function (e) {
            let answerId = e.target.getAttribute('data-answer-id');
            document.getElementById(`answer-text-${answerId}`).style.display = 'none';
            document.getElementById(`answer-edit-${answerId}`).style.display = 'block';
        });
    });

    // キャンセルボタンがクリックされた時の処理
    document.querySelectorAll('.cancel-edit-btn').forEach(function (btn) {
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            let answerId = e.target.getAttribute('data-answer-id');
            document.getElementById(`answer-edit-${answerId}`).style.display = 'none';
            document.getElementById(`answer-text-${answerId}`).style.display = 'block';
        });
    });
});
$(document).ready(function () {
    $('.thumbnail').click(function () {
        $('.thumbnail').removeClass('thumbnail-selected');
        $(this).addClass('thumbnail-selected');
    });
});

$(document).on('turbolinks:load', function () {
    $('.tabs-community li:first-child').each(function () {
        $(this).addClass('tab-active');
    });
    $('.tabs-stage div:first-child').each(function () {
        $(this).show();
    });

    $('.tabs-nav a').on('click', function (event) {
        event.preventDefault();
        $(this).parent().parent().find('li').removeClass('tab-active');
        $(this).parent().addClass('tab-active');
        $(this).parent().parent().parent().find('.tabs-stage .tabs-stage-element').hide();
        let hrefValue = $(this).attr('href');
        if (hrefValue.startsWith("#")) {
          $($(this).attr('href')).show();
        } else {
          window.location.href = $(this).attr('href')
        }

    });
});
$(document).ready(function () {
    $('.thumbnail').click(function () {
        $('.thumbnail').removeClass('thumbnail-selected');
        $(this).addClass('thumbnail-selected');
    });
});

$(document).ready(function () {
    let selectedRegion = $('#pet-prefecture-select').val();
    let citySelect = $('#pet-city-select');
    let cityVal = citySelect.data("city");
    $.ajax({
        url: '/prefectures_with_cities.json',
        method: 'GET',
        dataType: 'json',
        success: function (regionsWithCities) {
            let cities = regionsWithCities[selectedRegion] || [];

            citySelect.empty();
            citySelect.append($('<option>', { value: '', text: '市町村を選択' }));

            let found = false;

            $.each(cities, function (index, city) {
                let option = $('<option>', { value: city, text: city });

                if (cityVal !== undefined && cityVal === city) {
                    citySelect.append($('<option>', { value: cityVal, text: cityVal, selected: "selected" }));
                }
            });
            if (cityVal !== undefined && !found) {
                citySelect.append($('<option>', { value: cityVal, text: cityVal, selected: "selected" }));
            }
            let url_string = window.location.href;
            let url = new URL(url_string);
            let city = url.searchParams.get("city");
            $('.city-select').val(city);
        },
        error: function () {
            console.log('Error fetching regions with cities.');
        }
    });
    $(".btn-reason").click(function () {
        var checked = false;
        console.log(checked);
        $('.form-check-input').each(function () {
            if ($(this).is(':checked')) {
                checked = true;
                return false;
            }
        });
        console.log(checked);

        if (!checked) {
            alert("少なくとも1つの理由を選択してください。");
            return;
        }

        $(".new-reason").addClass("d-none");
        $(".reason-confirm").removeClass("d-none");
    });
});

$(document).ready(function () {
    let passwordField1 = document.getElementById('password_field1');
    let passwordField2 = document.getElementById('password_field2');
    if (passwordField1 && passwordField2) {
        if (window.innerWidth < 480) {
            passwordField1.removeAttribute('placeholder');
            passwordField2.removeAttribute('placeholder');
        } else {
            passwordField1.setAttribute('placeholder', '半角英数字6文字以上');
            passwordField2.setAttribute('placeholder', '半角英数字6文字以上');
        }
    }
    $("#year-select, #month-select, #day-select").change(function () {
        $("#unknown-radio").prop("checked", false);
    });
});
