$(document).ready(function () {
  const $previewButton = $("#preview-button");
  const $backButton = $("#back-button");
  const $inputSection = $("#input-section");
  const $previewSection = $("#preview-section");

  const $inquiryTypeInput = $("#inquiry-type-select");
  const $contentInput = $("#content-input");

  const $inquiryTypeError = $("#inquiry-type-error");
  const $contentError = $("#content-error");

  function clearErrors() {
    $inquiryTypeError.text("");
    $contentError.text("");
  }

  function validateInputs() {
    let isValid = true;

    clearErrors();

    if (!$inquiryTypeInput.val()) {
      $inquiryTypeError.text("お問い合わせ種目を選択してください。");
      isValid = false;
    }

    if (!$contentInput.val().trim()) {
      $contentError.text("お問い合わせ内容を入力してください。");
      isValid = false;
    }

    return isValid;
  }

  $previewButton.on("click", function () {
    if (!validateInputs()) {
      return;
    }

    $("#preview-inquiry-type").text($inquiryTypeInput.val());
    $("#preview-content").text($contentInput.val());

    $inputSection.addClass("d-none");
    $previewSection.removeClass("d-none");
  });

  $backButton.on("click", function () {
    $previewSection.addClass("d-none");
    $inputSection.removeClass("d-none");
  });
});
