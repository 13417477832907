$(document).ready(function() {
    const iconContext = require.context('../../assets/images/icon_transaction', false, /\.png$/);

    const icons = iconContext.keys().map(iconContext);

    function changeIcons() {
        $('.random-icon').each(function() {
            const randomIndex = Math.floor(Math.random() * icons.length);
            $(this).attr('src', icons[randomIndex]);
        });
    }

    setInterval(changeIcons, 2000);
});
