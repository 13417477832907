import "@popperjs/core";
import { Modal } from 'bootstrap';

$(document).ready(function () {
  const $form = $("#inquiry-form");
  const $closeModalButton = $("#close-modal");

  const thankYouModal = new Modal(document.getElementById("thank-you-modal"));

  $form.on("ajax:success", function () {
    thankYouModal.show();

    $form[0].reset();
  });

  $form.on("ajax:error", function (event, xhr) {
    const errors = xhr.responseJSON?.errors || ["エラーが発生しました。"];
    alert(errors.join("\n"));
  });

  $closeModalButton.on("click", function () {
    window.location.href = "/inquiries/new";
  });
});
