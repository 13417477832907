$(document).on('turbolinks:load', function () {
  $(document).ready(function () {
    $('#additional-info-form').addClass('d-none');
    $('#additional-pet').addClass('d-none');

    $('input[name="have_pet"]').change(function () {
      switch ($(this).val()) {
        case '0':
          $('#additional-pet').removeClass('d-none');
          $('#additional-info-form').addClass('d-none');
          break;
        case '1':
          $('#additional-pet').addClass('d-none');
          $('#additional-info-form').removeClass('d-none');
          break;
        case '2':
          $('#additional-pet').addClass('d-none');
          $('#additional-info-form').removeClass('d-none');
          break;
        default:
          break;
      }
    });

    const autoClick = document.getElementById("have_pet_yes");
    if (autoClick) {
      autoClick.click();
    }

    $(".btn-user").click(function (e) {
      var usernameValue = $('#user_name').val();
      var selectedPrefecture = $('#select-prefecture').val();
      var selectedCity = $('#select-city').val();
      var isValid = true;

      if (usernameValue === '') {
        $('.error-username').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-username').addClass('d-none');
      }

      if (!selectedPrefecture) {
        $('.error-prefecture').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-prefecture').addClass('d-none');
      }

      if (!selectedCity) {
        $('.error-city').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-city').addClass('d-none');
      }

      if (!isValid) {
        e.preventDefault();
      }
    });

    $(".create-pet").click(function (e) {
      var nameValue = $('input[name="pet[name]"]').val();
      var selectedPrefecture = $('#pet-prefecture-select').val();
      var selectedCity = $('#pet-city-select').val();
      var isValid = true;

      if (nameValue === '') {
        $('.error-name').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-name').addClass('d-none');
      }

      if (!selectedPrefecture) {
        $('.error-pet-prefecture').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-pet-prefecture').addClass('d-none');
      }

      if (!selectedCity) {
        $('.error-pet-city').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-pet-city').addClass('d-none');
      }

      if (!isValid) {
        e.preventDefault();
      }
    });

    var petCategorySelect = $('#pet-category-select');
    var petSubcategorySelect = $('#pet-subcategory-select');

    // Handle the change event of the category select
    petCategorySelect.on('change', function () {
      var categoryId = $(this).val();
      console.log(categoryId);
      petSubcategorySelect.empty();
      petSubcategorySelect.append('<option value="">選択してください</option>');

      // Make an AJAX request to fetch the subcategories for the selected category
      $.ajax({
        url: '/categories/' + categoryId + '/subcategories',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          // petSubcategorySelect.empty();
          // // Add the default prompt option
          // subcategorySelect.append('<option value="">中分類を選んでください</option>');
          petSubcategorySelect.empty();
          petSubcategorySelect.append('<option value="">選択してください</option>');
          // Append the fetched subcategories as options
          $.each(data, function (index, subcategory) {
            petSubcategorySelect.append('<option value="' + subcategory.id + '">' + subcategory.name + '</option>');
          });
        },
        error: function () {
          console.log('Error fetching subcategories.');
        }
      });
    });
  });
});
