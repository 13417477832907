$(document).ready(function() {
  let upload = false
  window.checkAndNextFormOwner = function (e) {
    var nameValue = $('#owner_name').val();
    var prefecture_select = $('.prefecture-select').val();
    var city_select = $('.city-select').val();
    var owner_email = $('#owner_email').val();
    var addition_location = $('#owner_addition_location').val();
    var owner_phone_number = $('#owner_phone_number').val();
    var isValid = true;
    var data = {
      nameValue: nameValue,
      prefecture_select: prefecture_select,
      city_select: city_select,
      owner_email: owner_email,
      addition_location: addition_location,
      owner_phone_number: owner_phone_number
    }

    if (nameValue.length > 15 || nameValue === '') {
      $('.error-name').removeClass('d-none');
      isValid = false;
    } else {
      $('.error-name').addClass('d-none');
    }

    if (!prefecture_select) {
      $('.error-prefecture').removeClass('d-none');
      isValid = false;
    } else {
      $('.error-prefecture').addClass('d-none');
    }

    if (!city_select) {
      $('.error-city').removeClass('d-none');
      isValid = false;
    } else {
      $('.error-city').addClass('d-none');
    }

    if (owner_email === '') {
      $('.error-email').removeClass('d-none');
      isValid = false;
    } else {
      $('.error-email').addClass('d-none');
    }

    if (addition_location === '') {
      $('.error-addition-location').removeClass('d-none');
      isValid = false;
    } else {
      $('.error-addition-location').addClass('d-none');
    }
    if (owner_phone_number === '' || !/^[0-9]*$/.test(owner_phone_number)) {
      isValid = false;
    } else {
      $('.error-phone-number').addClass('d-none');
    }

    if (!upload) {
      $('.error-image').removeClass('d-none');
      isValid = false;
    } else {
      $('.error-image').addClass('d-none');
    }

    checkEmailExistence(owner_email).then(isValidEmailExist => {
      console.log('Is email valid exist:', isValidEmailExist);
      if (isValidEmailExist == false) {
        $('.error-email-exist').removeClass('d-none');
        isValid = false;
      } else {
        $('.error-email-exist').addClass('d-none');
      }
      if (!isValid) {
        e.preventDefault();
      } else {
        setValue(data)
        $('.form_owner').addClass('d-none')
        $('.form-preview').removeClass('d-none')
      }
    });
  }

  function setValue(data) {
    let addition_location = data.prefecture_select + data.city_select + data.addition_location
    let owner_type = $("input[name='owner[owner_type]']:checked").val() === 'company' ? '会社' : '個人';
    $('.owner-prefecture').text(addition_location)
    $('.owner-name').text(data.nameValue)
    $('.owner-type').text(owner_type)
    $('.owner-email').text(data.owner_email)
    $('.owner-phone-number').text(data.owner_phone_number)
    if ($('#owner_hospital').is(":checked")) {
      $('#hospital').text('病院')
    } else {
      $('#hospital').text('')
    }
    if ($('#owner_hotel').is(":checked")) {
      $('#hotel').text('ホテル')
    } else {
      $('#hotel').text('')
    }
    if ($('#owner_advertising').is(":checked")) {
      $('#advertising').text('広告')
    } else {
      $('#advertising').text('')
    }
  }

  function checkEmailExistence(email) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/owners/checkmail',
        type: 'GET',
        data: { email: email },
        success: function(response) {
          console.log('AJAX success response:', response);
          resolve(response.exists);
        },
        error: function(xhr, status, error) {
          console.log('AJAX error response:', { status: status, error: error });
          resolve(false);
        }
      });
    });
  }
  

  window.backForm = function () {
    $('.form_owner').removeClass('d-none')
    $('.form-preview').addClass('d-none')
  }


  $("#owner_image_s3").on("change", function () {
    let preview = $('#imagePreview, #imagePreview2');
    let file = this.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      if (file.size > maxSize) {
        $('body').append(`
        <div class="modal fade" id="image_modal" data-bs-backdrop="true" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="content-madal-text d-flex justify-content-center fw-bold">
                  画像は10MBサイズ以下でアップお願いします。
                </div>
              </div>
            </div>
          </div>
        </div>
      `)
      $('#image_modal').show();
      $('#image_modal').addClass('show')
      $(document).on('click', '#image_modal button', function () {
        $('#image_modal').remove();
      })
        this.value = '';
        $('#imagePreview').prop("style", "display: none;");
        $('#imagePreview').prop("src", "");
        return
      }
    }
    if (file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        let reader = new FileReader();
        reader.onload = function (e) {
          preview.attr('src', e.target.result).show();
        }
        reader.readAsDataURL(file);
      }
      upload = true
    } else {
      upload = false
    }
  })

})

document.addEventListener("DOMContentLoaded", function () {
  function addPhoneNumberValidation(phoneNumberFieldId, errorDivId) {
    const phoneNumberField = document.getElementById(phoneNumberFieldId);
    const errorDiv = document.getElementById(errorDivId);

    if (phoneNumberField && errorDiv) {
      phoneNumberField.addEventListener("input", function () {
        const value = phoneNumberField.value;
        const valid = /^[0-9]*$/.test(value);

        errorDiv.style.display = valid ? "none" : "block";
      });
    }
  }

  addPhoneNumberValidation("owner_phone_number", "phone_number_owner_error");
});
